import { Alert, Button, Card, Flex, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { User } from 'stream-chat';
import Api from '../../api';

const urlParams = new URLSearchParams(window.location.search);

const apiBaseUrl = process.env.REACT_APP_API_URL!;
const initialCode = urlParams.get('code');
const initialName = urlParams.get('username');
const inventJwt = urlParams.get('invent-jwt') ?? urlParams.get('access_token') ?? undefined;
const api = new Api(apiBaseUrl, inventJwt);

export interface SignInProps {
  onSignIn: (user: User, token: string) => void;
}

export const SignIn = (props: SignInProps) => {
  const [form] = Form.useForm();

  const [error, setError] = useState<string | null>(null);
  const [initializing, setInitializing] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = ({ code, name }: { code: string; name: string }) => {
    setLoading(true);
    api
      .chatSignInWithCode(code, name)
      .then(({ user, token }) => {
        props.onSignIn(user, token);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message ?? error.error);
        setLoading(false);
      });
  };

  const handleSignWithToken = () => {
    api
      .chatSignIn()
      .then(({ user, token }) => {
        props.onSignIn(user, token);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message ?? error.error);
      });
  };

  useEffect(() => {
    if (inventJwt) {
      setInitializing(true);
      handleSignWithToken();
    } else if (initialCode && initialName) {
      setInitializing(true);
      handleSubmit({ code: initialCode, name: initialName });
    } else if (initialCode) {
      setInitializing(true);
      handleSubmit({ code: initialCode, name: 'Oleg' });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (initializing) {
    return (
      <Flex
        align='center'
        justify='center'
        style={{ height: '100%', background: 'var(--primary-color)' }}
      >
        <p style={{ color: 'white' }}>Loading...</p>
      </Flex>
    );
  }
  return (
    <Flex
      align='center'
      justify='center'
      style={{ height: '100%', background: 'var(--primary-color)' }}
    >
      <Card title='Invent Chat' style={{ width: 300 }}>
        <Form
          layout='vertical'
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            code: initialCode ?? 'intranet',
            name: initialName ?? '',
          }}
        >
          <Form.Item label='Join Code' name='code'>
            <Input size='large' placeholder='code' />
          </Form.Item>
          <Form.Item label='Name' name='name'>
            <Input size='large' placeholder='unique name' />
          </Form.Item>
          {!!error && <Alert style={{ marginBottom: 20 }} message={error} type='error' />}
          <Form.Item>
            <Button size='large' block type='primary' loading={loading} htmlType='submit'>
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Flex>
  );
};
