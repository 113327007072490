import { useEffect, useState } from 'react';
import { StreamChat, User } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import { useChecklist } from './ChecklistTasks';
import { ChannelContainer } from './components/ChannelContainer/ChannelContainer';
import { Sidebar } from './components/Sidebar/Sidebar';
import { SideDrawer } from './components/Sidebar/SideDrawer';
import {
  SIDE_BAR_WIDTH,
  SideDrawerProvider,
  useSideDrawerContext,
} from './context/SideDrawerContext';
import { WorkspaceProvider } from './context/WorkspaceContext';
import type { StreamChatType } from './types';
import { SignIn } from './components/AdminPanel/SignIn';

const urlParams = new URLSearchParams(window.location.search);

const streamApiKey = process.env.REACT_APP_STREAM_API_KEY!;
const theme = urlParams.get('theme') || 'light';
const targetOrigin = process.env.REACT_APP_TARGET_ORIGIN;

const client = StreamChat.getInstance<StreamChatType>(streamApiKey, {
  enableInsights: true,
  enableWSFallback: true,
  timeout: 10000,
});

const ChatApp = ({ team }: { team: string }) => {
  useChecklist({ chatClient: client, targetOrigin: targetOrigin });
  const { shouldUseDrawer } = useSideDrawerContext();

  useEffect(() => {
    const handleColorChange = (color: string) => {
      const root = document.documentElement;
      if (color.length && color.length === 7) {
        root.style.setProperty('--primary-color', `${color}E6`);
        root.style.setProperty('--primary-color-alpha', `${color}1A`);
      }
    };

    window.addEventListener('message', (event) => handleColorChange(event.data));
    return () => {
      client.disconnectUser();
      window.removeEventListener('message', (event) => handleColorChange(event.data));
    };
  }, []);

  return (
    <>
      <div className='app__wrapper str-chat'>
        <Chat client={client} theme={`team ${theme}`}>
          <WorkspaceProvider team={team}>
            {shouldUseDrawer ? (
              <SideDrawer>
                <Sidebar />
              </SideDrawer>
            ) : (
              <div style={{ width: SIDE_BAR_WIDTH }}>
                <Sidebar />
              </div>
            )}
            <ChannelContainer />
          </WorkspaceProvider>
        </Chat>
      </div>
    </>
  );
};

const App = () => {
  const [team, setTeam] = useState<string>('');

  const handleSignIn = (user: User, token: string) => {
    client.connectUser({ id: user.id }, token);
    setTeam(user.teams?.[0] ?? '');
    const debugObject = {
      userId: user.id,
      username: user.username,
      name: user.name,
      token,
      streamApiKey,
      teams: user.teams,
    };
    console.debug(JSON.stringify(debugObject, null, 2));
  };

  if (!team) {
    return <SignIn onSignIn={handleSignIn} />;
  }

  return (
    <SideDrawerProvider>
      <ChatApp team={team} />
    </SideDrawerProvider>
  );
};

export default App;
