import { ChangeEvent } from 'react';
import { useMessageInputContext } from 'stream-chat-react';

const AttachmentIcon = () => (
  <svg width='20' height='20' fill='none' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        d='M31.778,13.394l-14.85,14.849l2.828,2.828l14.85-14.848c1.169-1.17,3.072-1.169,4.243-0.001
		c1.169,1.17,1.169,3.073,0,4.243L35.314,24l-0.001-0.001l-6.033,6.034l-5.28,5.279L24,35.313
		c-3.119,3.119-8.194,3.118-11.313,0.001c-3.118-3.119-3.118-8.194,0.001-11.313L28.242,8.443l-2.828-2.828L9.859,21.173
		c-2.267,2.267-3.515,5.279-3.516,8.484c0,3.205,1.248,6.219,3.515,8.485c2.34,2.339,5.411,3.508,8.484,3.508
		c3.072,0,6.146-1.17,8.485-3.509l4.482-4.483l10.368-10.365c2.728-2.73,2.728-7.171-0.001-9.9
		C38.947,10.665,34.507,10.666,31.778,13.394z'
      />
    </g>
  </svg>
);

const AttachmentUploadButton = () => {
  const { cooldownRemaining, uploadNewFiles, isUploadEnabled, maxFilesLeft } =
    useMessageInputContext();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const files = e.currentTarget.files;

    if (files && files.length > 0) {
      uploadNewFiles(files);
    }
  }

  if (!isUploadEnabled || maxFilesLeft === 0 || cooldownRemaining) {
    return null;
  }

  return (
    <label className='message-input__controls-button' style={{ paddingLeft: 6, paddingRight: 6 }}>
      <input
        type='file'
        style={{ visibility: 'hidden', width: 0, height: 0 }}
        onChange={handleChange}
      />
      <AttachmentIcon />
    </label>
  );
};

export default AttachmentUploadButton;
