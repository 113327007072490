import type { Channel } from 'stream-chat';
import { ChannelSort } from 'stream-chat';
import { ChannelList, useChatContext } from 'stream-chat-react';
import { StreamChatType } from '../../types';
import { ChannelPreview } from '../ChannelPreview/ChannelPreview';
import { ChannelSearch } from '../ChannelSearch/ChannelSearch';
import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import {
  EmptyDMChannelListIndicator,
  EmptyGroupChannelListIndicator,
} from './EmptyChannelListIndicator';
import { useSideDrawerContext } from '../../context/SideDrawerContext';

const options = { state: true, watch: true, presence: true, limit: 100 };
const sort: ChannelSort<StreamChatType> = { name: 1 };

const customChannelTeamFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'team');
};

const customChannelMessagingFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'messaging');
};

const TeamChannelsList = ({ setActiveChannelOnMount }: { setActiveChannelOnMount: boolean }) => (
  <ChannelList
    channelRenderFilterFn={customChannelTeamFilter}
    filters={{ type: 'team' }}
    options={options}
    sort={sort}
    setActiveChannelOnMount={setActiveChannelOnMount}
    EmptyStateIndicator={EmptyGroupChannelListIndicator}
    List={(listProps) => <TeamChannelList {...listProps} type='team' />}
    Preview={(previewProps) => <ChannelPreview {...previewProps} type='team' />}
  />
);

const MessagingChannelsList = ({ userId }: { userId: string }) => (
  <ChannelList
    channelRenderFilterFn={customChannelMessagingFilter}
    filters={{ type: 'messaging', members: { $in: [userId] } }}
    options={options}
    sort={sort}
    setActiveChannelOnMount={false}
    EmptyStateIndicator={EmptyDMChannelListIndicator}
    List={(listProps) => <TeamChannelList {...listProps} type='messaging' />}
    Preview={(previewProps) => <ChannelPreview {...previewProps} type='messaging' />}
  />
);

export const Sidebar = () => {
  const { drawerIsOpen } = useSideDrawerContext();
  const { client } = useChatContext<StreamChatType>();

  return (
    <div className='sidebar'>
      <div className='channel-list-bar'>
        <div className='channel-list-bar__header'>
          <p className='channel-list-bar__header__text'>Invent Chat</p>
        </div>
        <ChannelSearch />
        <TeamChannelsList setActiveChannelOnMount={!drawerIsOpen} />
        <MessagingChannelsList userId={client?.userID ?? ''} />
      </div>
    </div>
  );
};
