import { AdminPanelHeader } from './AdminPanelHeader';
import { AdminPanelFooter } from './AdminPanelFooter';
import { InputField } from './InputField';
import { UserList } from './UserList';

import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { useWorkspaceContext } from '../../context/WorkspaceContext';

export const CreateChannel = () => {
  const { closeAdminPanel } = useWorkspaceContext();
  const { createChannelType, name, handleInputChange, handleSubmit, errors } =
    useAdminPanelFormState();

  return (
    <div className='admin-panel__form'>
      <AdminPanelHeader
        onClose={closeAdminPanel}
        title={createChannelType === 'team' ? 'Create a New Channel' : 'Send a Direct Message'}
      />
      {createChannelType === 'team' && (
        <InputField
          error={errors.name}
          label='Name'
          value={name}
          onChange={handleInputChange}
          placeholder='channel-name (no spaces)'
        />
      )}
      <UserList show='all-but-current' />
      <AdminPanelFooter
        onButtonClick={handleSubmit}
        buttonText={createChannelType === 'team' ? 'Create Channel' : 'Create Message Group'}
      />
    </div>
  );
};
