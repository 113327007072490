import React, { useCallback, useContext, useState } from 'react';

export type Workspace = 'Chat' | 'Admin-Channel-Edit' | 'Admin-Channel-Create__team' | 'Admin-Channel-Create__messaging';

type WorkspaceContextType = {
  activeWorkspace: Workspace;
  closeAdminPanel: () => void;
  displayWorkspace: (w: Workspace) => void;
  pinnedMessageListOpen: boolean;
  togglePinnedMessageListOpen: () => void;
  closePinnedMessageListOpen: () => void;
  team?: string;
}

const WorkspaceContext = React.createContext<WorkspaceContextType | null>(null);


export const WorkspaceProvider = ({ children, team }: { children: React.ReactNode, team?: string }) => {
  const [activeWorkspace, setActiveWorkspace] = useState<Workspace>('Chat');
  const [pinnedMessageListOpen, setPinnedMessageListOpen] = useState(false);

  const displayWorkspace: WorkspaceContextType['displayWorkspace'] = useCallback((workspace) => {
    setActiveWorkspace(workspace);
    setPinnedMessageListOpen(false);
  }, [setActiveWorkspace]);

  const closeAdminPanel = useCallback(() => {
    displayWorkspace('Chat');
  }, [displayWorkspace]);

  const togglePinnedMessageListOpen = useCallback(() => setPinnedMessageListOpen((prev) => !prev), []);
  const closePinnedMessageListOpen = useCallback(() => setPinnedMessageListOpen(false), []);

  return (
    <WorkspaceContext.Provider value={{
      activeWorkspace,
      closeAdminPanel,
      displayWorkspace,
      pinnedMessageListOpen,
      closePinnedMessageListOpen,
      togglePinnedMessageListOpen,
      team
    }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaceContext = (): WorkspaceContextType => {
  const context = useContext(WorkspaceContext)
  if (!context) {
    throw new Error ('useWorkspaceContext must be used inside a WorkspaceContext')
  }
  return context
};