import { User } from 'stream-chat';

export default class Api {
  private readonly baseUrl: string;
  private readonly inventToken?: string;

  constructor(baseUrl: string, inventToken?: string) {
    this.baseUrl = baseUrl;
    this.inventToken = inventToken;
  }

  async fetch<T>(path: string, method: string = 'GET', body?: Record<string, any>) {
    const url = `${this.baseUrl}${path}`;
    const response = await fetch(url, {
      method,
      headers: this.inventToken ? { Authorization: `Bearer ${this.inventToken}` } : {},
      body: body && JSON.stringify(body),
    });

    const responseBody = await response.json();
    if (response.ok) {
      return responseBody as T;
    }
    throw responseBody;
  }

  async chatSignIn(): Promise<{ user: User; token: string }> {
    return this.fetch(`/chat/sign-in`, 'POST');
  }

  async chatSignInWithCode(code: string, name: string): Promise<{ user: User; token: string }> {
    return this.fetch(`/chat/sign-in`, 'POST', { code, name });
  }
}
