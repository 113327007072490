import { useChatContext } from 'stream-chat-react';
import { useSideDrawerContext } from '../../context/SideDrawerContext';
import { StreamChatType } from '../../types';
import { useEffect, useState } from 'react';

export const SideDrawer = ({ children }: { children: React.ReactNode; team?: string }) => {
  const [currentChannelId, setCurrentChannelId] = useState<string | undefined>();
  const { drawerIsOpen, toggleDrawer, drawerWidth } = useSideDrawerContext();
  const { channel } = useChatContext<StreamChatType>();

  useEffect(() => {
    if (channel?.cid !== currentChannelId) {
      setCurrentChannelId(channel?.cid ?? '');
      if (drawerIsOpen) {
        toggleDrawer();
      }
      console.debug(channel?.cid);
    }
  }, [channel?.cid, currentChannelId, drawerIsOpen, toggleDrawer]);

  const drawerClasses = drawerIsOpen ? 'side-drawer open' : 'side-drawer';

  return (
    <div>
      <div className={drawerClasses} style={{ width: drawerWidth }}>
        {children}
      </div>
      {drawerIsOpen && <div className='drawer-overlay' onClick={toggleDrawer}></div>}
    </div>
  );
};
