import { useChatContext } from 'stream-chat-react';
import { useWorkspaceContext } from '../../context/WorkspaceContext';
import { StreamChatType } from '../../types';
import { AdminPanelFooter } from './AdminPanelFooter';
import { AdminPanelHeader } from './AdminPanelHeader';
import { InputField } from './InputField';
import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { UserList } from './UserList';

export const EditChannel = () => {
  const { client, channel } = useChatContext<StreamChatType>();
  const { closeAdminPanel } = useWorkspaceContext();
  const { name, handleInputChange, handleSubmit, errors } = useAdminPanelFormState();

  const handleLeaveChannel = () => {
    if (client.userID) {
      channel?.removeMembers([client.userID]);
    }
  };
  console.log(handleLeaveChannel);

  return (
    <div className='admin-panel__form'>
      <AdminPanelHeader onClose={closeAdminPanel} title='Edit Channel' />
      <InputField label='Name' value={name} error={errors.name} onChange={handleInputChange} />
      <UserList show='current-channel' />
      <UserList show='not-in-current-channel' />
      <AdminPanelFooter buttonText='Save Changes' onButtonClick={handleSubmit} />
      {/* <AdminPanelFooter buttonText='Leave channel' onButtonClick={handleLeaveChannel} /> */}
    </div>
  );
};
