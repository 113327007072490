import React, { useContext, useEffect, useMemo, useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

export const SIDE_BAR_WIDTH = 240;

type SideDrawerContextType = {
  shouldUseDrawer: boolean;
  drawerIsOpen: boolean;
  toggleDrawer: () => void;
  drawerWidth: number;
};

const SideDrawerContext = React.createContext<SideDrawerContextType | null>(null);
const breakpoint = 700;

export const SideDrawerProvider = ({ children }: { children: React.ReactNode; team?: string }) => {
  const { width } = useWindowDimensions();
  const shouldUseDrawer = useMemo(() => {
    return width < breakpoint;
  }, [width]);
  const [needsFullWidth, setNeedsFullWidth] = useState(true);
  const [drawerIsOpen, setDrawerIsOpen] = useState(shouldUseDrawer);

  const toggleDrawer = () => {
    setNeedsFullWidth(false);
    setDrawerIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (!shouldUseDrawer) {
      setNeedsFullWidth(false);
      setDrawerIsOpen(false);
    }
  }, [shouldUseDrawer, needsFullWidth]);

  return (
    <SideDrawerContext.Provider
      value={{
        drawerIsOpen,
        shouldUseDrawer,
        toggleDrawer,
        drawerWidth: needsFullWidth ? width : SIDE_BAR_WIDTH,
      }}
    >
      {children}
    </SideDrawerContext.Provider>
  );
};

export const useSideDrawerContext = (): SideDrawerContextType => {
  const context = useContext(SideDrawerContext);
  if (!context) {
    throw new Error('useSideDrawerContext must be used inside a SideDrawerContext');
  }
  return context;
};
