import { useSideDrawerContext } from '../../context/SideDrawerContext';

const MenuIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4 6H20M4 12H20M4 18H20'
      stroke='black'
      strokeWidth='2'
      strokeOpacity='0.80'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const DrawerButton = () => {
  const { toggleDrawer, shouldUseDrawer } = useSideDrawerContext();

  if (shouldUseDrawer) {
    return (
      <button onClick={toggleDrawer}>
        <MenuIcon />
      </button>
    );
  }
  return null;
};
