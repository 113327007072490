import React, { PropsWithChildren } from 'react';

import { AddChannelButton } from './AddChannelButton';

import { useWorkspaceContext, Workspace } from '../../context/WorkspaceContext';

import type { ChannelListMessengerProps } from 'stream-chat-react';
import clsx from 'clsx';
import { useSideDrawerContext } from '../../context/SideDrawerContext';

export type TeamChannelListProps = ChannelListMessengerProps & {
  type: string;
};

const ChannelList = (props: PropsWithChildren<TeamChannelListProps>) => {
  const { children, error = false, loading, type } = props;

  const { drawerIsOpen, toggleDrawer } = useSideDrawerContext();
  const { displayWorkspace } = useWorkspaceContext();

  const handleAddChannelClick = () => {
    displayWorkspace(`Admin-Admin-Channel-Create__${type}` as Workspace);
    if (drawerIsOpen) {
      toggleDrawer();
    }
  };

  if (error) {
    return type === 'team' ? (
      <div className='team-channel-list'>
        <p className='team-channel-list__message'>
          Connection error, please wait a moment and try again.
        </p>
      </div>
    ) : null;
  }

  if (loading) {
    return (
      <div className='team-channel-list'>
        <p className='team-channel-list__message loading'>
          {type === 'team' ? 'Channels' : 'Messages'} loading....
        </p>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'team-channel-list',
        `team-channel-list--${type === 'team' ? 'group' : 'dm'}`,
      )}
    >
      <div className='team-channel-list__header'>
        <p className='team-channel-list__header__title'>
          {type === 'team' ? 'Channels' : 'Direct Messages'}
        </p>
        <AddChannelButton onClick={handleAddChannelClick} />
      </div>
      {children}
    </div>
  );
};

export const TeamChannelList = React.memo(ChannelList);
