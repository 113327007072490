import { useCallback, useEffect, useState } from 'react';
import { logChatPromiseExecution, MessageResponse } from 'stream-chat';
import {
  defaultPinPermissions,
  MessageInput,
  MessageList,
  MessageToSend,
  PinEnabledUserRoles,
  Thread,
  useChannelActionContext,
  useChatContext,
  Window,
} from 'stream-chat-react';
import { useGiphyInMessageContext } from '../../context/GiphyInMessageFlagContext';
import type { StreamChatType } from '../../types';
import { AdminPanelFooter } from '../AdminPanel/AdminPanelFooter';
import { PinnedMessageList } from '../PinnedMessageList/PinnedMessageList';
import { TeamChannelHeader } from '../TeamChannelHeader/TeamChannelHeader';
import { ThreadMessageInput } from '../TeamMessageInput/ThreadMessageInput';

export const ChannelInner = () => {
  const { client, channel } = useChatContext<StreamChatType>();
  const { inputHasGiphyMessage, clearGiphyFlag } = useGiphyInMessageContext();
  const { sendMessage } = useChannelActionContext<StreamChatType>();
  const [userIsInChannel, setUserIsInChannel] = useState(true);

  // todo: migrate to channel capabilities once migration guide is available
  const teamPermissions: PinEnabledUserRoles = { ...defaultPinPermissions.team, user: true };
  const messagingPermissions: PinEnabledUserRoles = {
    ...defaultPinPermissions.messaging,
    user: true,
  };

  const pinnedPermissions = {
    ...defaultPinPermissions,
    team: teamPermissions,
    messaging: messagingPermissions,
  };

  useEffect(() => {
    if (client.userID && channel?.state.members[client.userID]) {
      setUserIsInChannel(true);
    } else {
      setUserIsInChannel(false);
    }
  }, [client, channel?.state]);

  const overrideSubmitHandler = useCallback(
    (message: MessageToSend) => {
      let updatedMessage = {
        attachments: message.attachments,
        mentioned_users: message.mentioned_users,
        parent_id: message.parent?.id,
        parent: message.parent as MessageResponse,
        text: message.text,
      };

      const isReply = !!updatedMessage.parent_id;

      if (inputHasGiphyMessage(isReply)) {
        const updatedText = `/giphy ${message.text}`;
        updatedMessage = { ...updatedMessage, text: updatedText };
      }

      if (sendMessage) {
        const sendMessagePromise = sendMessage(updatedMessage);
        logChatPromiseExecution(sendMessagePromise, 'send message');
        clearGiphyFlag(isReply);
      }
    },
    [inputHasGiphyMessage, sendMessage, clearGiphyFlag],
  );

  const handleJoin = async () => {
    if (client.userID) {
      await channel?.addMembers([client.userID]);
      setUserIsInChannel(true);
    }
  };

  return (
    <>
      <Window>
        <TeamChannelHeader />
        <MessageList disableQuotedMessages={true} pinPermissions={pinnedPermissions} />
        {userIsInChannel ? (
          <MessageInput
            grow
            overrideSubmitHandler={overrideSubmitHandler}
            // audioRecordingConfig={{ transcoderConfig: { encoder: encodeToMp3 } }}
            // audioRecordingEnabled
            asyncMessagesMultiSendEnabled
          />
        ) : (
          <div>
            <AdminPanelFooter onButtonClick={handleJoin} buttonText={'Join Channel'} />
          </div>
        )}
      </Window>
      <Thread
        additionalMessageInputProps={{
          grow: true,
          Input: ThreadMessageInput,
          overrideSubmitHandler,
        }}
      />
      <PinnedMessageList />
    </>
  );
};
