import { ChangeEventHandler } from 'react';
import { ValidationError } from './ValidationError';

type InputFieldProps = {
  label: string;
  value: string;
  error: string | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
};

export const InputField = ({
  label,
  value = '',
  error,
  placeholder = 'channel-name',
  onChange,
}: InputFieldProps) => {
  return (
    <div className='channel-name-input-field'>
      <h2>
        <span>{label}</span>
        <ValidationError errorMessage={error} />
      </h2>
      <input onChange={onChange} placeholder={placeholder} type='text' value={value} />
    </div>
  );
};
