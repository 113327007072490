import { useEffect, useMemo, useState } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';
import type { UserFilters, UserResponse } from 'stream-chat';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import type { StreamChatType } from '../../types';
import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { ValidationError } from './ValidationError';
import { ASC } from '../../streamUtils';

dayjs.extend(relativeTime);

export interface ListContainerProps {
  header: string;
  children: React.ReactNode;
  hideInvite?: boolean;
}

const ListContainer = (props: ListContainerProps) => {
  const { children, header, hideInvite } = props;
  const { errors, createChannelType } = useAdminPanelFormState();
  const showHeading = !createChannelType || createChannelType === 'team';
  return (
    <div className='user-list__container'>
      {showHeading && (
        <h2>
          <span>{header}</span>
          <ValidationError errorMessage={errors.members} />
        </h2>
      )}
      <div className='user-list__header user-list__row'>
        <div className='user-list__column-block'>
          <p>User</p>
          <p className='user-list__column--last-active'>Last Active</p>
        </div>
        {hideInvite !== true && (
          <div className='user-list__column--checkbox'>
            <p>Invite</p>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

type UserItemProps = {
  user: UserResponse<StreamChatType>;
  hideInvite?: boolean;
};

function formatLastActive(lastActive?: string) {
  if (lastActive) {
    return dayjs(lastActive).fromNow();
  }
  return '';
}

const UserItem = ({ user, hideInvite }: UserItemProps) => {
  const { handleMemberSelect } = useAdminPanelFormState();

  const lastActive = formatLastActive(user.last_active);
  const title = user.name || user.id;

  return (
    <label htmlFor={user.id} title={title} className='user-list__row'>
      <div className='user-list__column-block'>
        <div className='user-list__column--user-data'>
          <Avatar image={user.image} name={title} />
          <p className='user-item__name'>{title}</p>
        </div>
        <p className='user-list__column--last-active'>{lastActive}</p>
      </div>
      {hideInvite !== true && (
        <div className='user-list__column--checkbox'>
          <input
            type='checkbox'
            name='members'
            id={user.id}
            value={user.id}
            onChange={handleMemberSelect}
          />
        </div>
      )}
    </label>
  );
};

type UserListLoadState = 'loading' | 'error' | 'empty';

const LOAD_STATE_NOTIFICATION: Record<UserListLoadState, string> = {
  empty: 'No users found.',
  error: 'Error loading, please refresh and try again.',
  loading: 'Loading users...',
};

export interface UserListProps {
  show: 'all' | 'all-but-current' | 'current-channel' | 'not-in-current-channel';
}

export const UserList = ({ show }: UserListProps) => {
  const { client, channel } = useChatContext<StreamChatType>();
  const { createChannelType } = useAdminPanelFormState();
  const [loadState, setLoadState] = useState<UserListLoadState | null>(null);
  const [users, setUsers] = useState<UserResponse<StreamChatType>[] | undefined>();

  const currentUserId = client.user?.id;
  const members = channel?.state?.members;

  const filter: UserFilters = useMemo(() => {
    if (show === 'all') {
      return {};
    }
    if (show === 'all-but-current' && currentUserId) {
      return { id: { $nin: [currentUserId] } };
    }
    if (show === 'current-channel' && members) {
      return { id: { $in: Object.keys(members) } };
    }
    if (show === 'not-in-current-channel' && members) {
      return { id: { $nin: Object.keys(members) } };
    }
    return {};
  }, [currentUserId, members, show]);

  const header = useMemo(() => {
    if (show === 'current-channel') {
      return 'Users';
    }
    return 'Add Users';
  }, [show]);

  useEffect(() => {
    const getUsers = async () => {
      if (loadState) return;
      setLoadState('loading');

      try {
        const response = await client.queryUsers(filter, { name: ASC }, { limit: 8 });

        if (response.users.length) {
          setUsers(response.users);
        } else {
          setLoadState('empty');
        }
      } catch (event) {
        setLoadState('error');
      }

      setLoadState(null);
    };

    if (client) getUsers();
  }, [client, filter, createChannelType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ListContainer header={header} hideInvite={show === 'current-channel'}>
      {loadState ? (
        <div className='user-list__message'>{LOAD_STATE_NOTIFICATION[loadState]}</div>
      ) : (
        users?.length &&
        users.map((user, i) => (
          <UserItem key={user.id} user={user} hideInvite={show === 'current-channel'} />
        ))
      )}
    </ListContainer>
  );
};
